import React from 'react'
import { navigate } from 'gatsby'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { sort, reverse } from 'ramda'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Layout from '../../templates/LiveLayout'
import EnhancedTableHead from '../../components/stats/TableHead'
import { Data } from '../../pages/dashboard'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from '../../constants/colors'
import styled from 'styled-components'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withErrorHandler } from '../../components/errorHandler'
import { PageRendererProps } from 'gatsby'
import { DateTime } from 'luxon'
import { imagePlaceholder } from '../../components/WorkoutsRow/Components/WorkoutItem'
import { WorkoutResponse } from '../../redux/actions'
import { api, Methods } from '../../services/httpService'
import { HeadCell } from '../../components/stats/UserTable'
import Button from '@material-ui/core/Button'
import { TRAINER_WORKOUT_VIDEO_ROUTE } from '../../constants/routes'
import ManageWorkoutActions from '../../components/ManageWorkouts/ManageWorkoutActions'

export const Profile = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
`

export type Order = 'asc' | 'desc'

type GenericSort = <T>(value: T[], order: Order, orderBy: keyof T) => T[]

export const sorter: GenericSort = <T,>(
  array: T[],
  order: Order, // 'asc' or 'desc'
  orderBy: keyof T,
) => {
  const sorted = sort((a: T, b: T) => {
    if (typeof a[orderBy] === 'number') {
      return Number(a[orderBy]) - Number(b[orderBy])
    } else {
      return a[orderBy].toString().localeCompare(b[orderBy].toString())
    }
  }, array)

  return order === 'asc' ? sorted : reverse(sorted)
}

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

const headCells: HeadCell[] = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    label: 'Workout',
    isUnsortable: true,
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'uploadStatus',
    numeric: false,
    disablePadding: false,
    label: 'Upload Status',
    isUnsortable: true,
  },
  {
    id: 'published',
    numeric: false,
    disablePadding: false,
    label: 'Published',
  },
]

const ManageWorkouts = (props: PageRendererProps) => {
  const classes = useStyles()
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState('createdAt')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const queryClient = useQueryClient()

  const { isLoading, data } = useQuery<WorkoutResponse[]>(
    'workouts',
    async () => api<WorkoutResponse[]>(Methods.get, '/v2/workout'),
  )
  const publishWorkout = useMutation(
    async (workout: WorkoutResponse) =>
      await api(Methods.patch, `/workout/${workout.id}`, {
        published: !workout.published,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries('workouts'),
    },
  )
  const deleteWorkout = useMutation(
    async (workout: WorkoutResponse) =>
      await api(Methods.delete, `/workout/${workout.id}`),
    {
      onSuccess: () => queryClient.invalidateQueries('workouts'),
    },
  )

  const dense = false

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleCellClick = (id: number) =>
    navigate(TRAINER_WORKOUT_VIDEO_ROUTE(id))

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (data?.length || 0) - page * rowsPerPage)

  console.log('data', data)
  return (
    <ThemeProvider theme={theme}>
      <Layout title="Manage Workouts" location={props.location}>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 16 }}
                  onClick={() => queryClient.invalidateQueries('workouts')}
                >
                  Refresh
                </Button>
              </div>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={0}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={() => null}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                    headCells={headCells}
                  />
                  <TableBody>
                    {/* @ts-ignore */}
                    {sorter<WorkoutResponse>(data, order, orderBy)
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row: WorkoutResponse) => (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          selected={false}
                        >
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            align="left"
                            padding="default"
                            onClick={() => handleCellClick(row.id)}
                          >
                            <Profile>
                              <img
                                src={
                                  (row.thumbnail as string) || imagePlaceholder
                                }
                                style={{
                                  width: 110,
                                  borderRadius: 6,
                                  margin: 0,
                                  height: 70,
                                  objectFit: 'cover',
                                }}
                              />

                              <div style={{ marginRight: 20 }} />

                              <div style={{ maxWidth: 300 }}>
                                {row.title}
                                <br />
                                <span style={{ color: 'rgba(0,0,0,.4)' }}>
                                  {row.description &&
                                    (row.description as string).substring(
                                      0,
                                      70,
                                    )}
                                </span>
                              </div>
                            </Profile>
                          </TableCell>

                          {/* created at date */}
                          <TableCell
                            align="left"
                            onClick={() => handleCellClick(row.id)}
                          >
                            {DateTime.fromISO(row.createdAt as string).toFormat(
                              'ff',
                            )}
                          </TableCell>

                          {/* upload status */}
                          <TableCell
                            align="left"
                            onClick={() => handleCellClick(row.id)}
                          >
                            {row.uploadStatus}
                          </TableCell>

                          {/* publish, edit, delete */}
                          <TableCell
                            component="th"
                            // id={labelId}
                            scope="row"
                            align="left"
                            padding="default"
                          >
                            <ManageWorkoutActions
                              workout={row}
                              publishWorkout={workout =>
                                publishWorkout.mutate(workout)
                              }
                              deleteWorkout={workout =>
                                deleteWorkout.mutate(workout)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        )}
      </Layout>
    </ThemeProvider>
  )
}

export default withErrorHandler(ManageWorkouts)
