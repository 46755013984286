import React, { useState } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { navigate } from 'gatsby'
import { TRAINER_EDIT_VIDEO } from '../../constants/routes'
import { WorkoutResponse } from '../../redux/actions'

interface Props {
  workout: WorkoutResponse
  publishWorkout: (workout: WorkoutResponse) => void
  deleteWorkout: (workout: WorkoutResponse) => void
}

const ManageWorkoutActions = (props: Props) => {
  const { workout, publishWorkout, deleteWorkout } = props
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
  return (
    <>
      <Button
        variant="outlined"
        color={workout.published ? 'secondary' : 'primary'}
        disableElevation
        disabled={workout.uploadStatus === 'processing'}
        style={{
          marginTop: 10,
          marginRight: 0,
          marginLeft: 8,
          width: 90,
        }}
        onClick={e => {
          e.preventDefault()
          publishWorkout(workout)
        }}
        id={`${workout.published ? 'workoutUnpublish' : 'workoutPublish'}${
          workout.title
        }`}
      >
        {false ? (
          <CircularProgress size="1.5rem" />
        ) : workout.published ? (
          'Unpublish'
        ) : (
          'Publish'
        )}
      </Button>
      <Button
        variant="text"
        color="secondary"
        disableElevation
        style={{ marginTop: 10 }}
        onClick={() =>
          navigate(TRAINER_EDIT_VIDEO, {
            state: { editWorkoutData: workout },
          })
        }
        id={`workoutEdit${workout.title}`}
      >
        Edit
      </Button>
      <Button
        variant="text"
        color="secondary"
        disableElevation
        style={{ marginTop: 10 }}
        onClick={() => setOpenDeleteDialog(true)}
        id={`workoutDelete${workout.title}`}
      >
        Delete
      </Button>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Delete workout{' '}
          <span style={{ fontStyle: 'italic' }}>{`"${workout.title}"`}</span> ?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this workout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={e => {
              e.preventDefault()
              deleteWorkout(workout)
              setOpenDeleteDialog(false)
            }}
            color="primary"
            autoFocus
            id={`deleteWorkoutConfirm${workout.title}`}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ManageWorkoutActions
